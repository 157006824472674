import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    role: string;
    queryParams?: any;
}

export interface ChildrenItems {
    path?: string;
    fullpath?: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/evento/listado',
        title: 'Eventos',
        type: 'link',
        icontype: 'calendar_today',
        role: 'ligas_venta',
    },
    {
        path: '/formulario/listado',
        title: 'Formularios',
        type: 'link',
        icontype: 'assignment',
        role: 'admin',
    },
    /*{
        path: '/vinculacion/listado',
        title: 'Vinculaciones',
        type: 'link',
        icontype: 'extension',
        role: 'admin',
    },
    {
        path: '/avisos/vinculacion_video',
        title: 'Vinculaciones Video',
        type: 'link',
        icontype: 'movie',
        role: 'admin',
    },*/
    {
        path: '/avisos/categoria',
        title: 'Categorias',
        type: 'link',
        icontype: 'category',
        role: 'admin',
    },
    {
        path: '/avisos/categoriavirtual',
        title: 'Categorias SV',
        type: 'link',
        icontype: 'category',
        role: 'admin',
    },
    {
        path: '/avisos/sala',
        title: 'Salas',
        type: 'link',
        icontype: 'room',
        role: 'admin',
    },
    {
        path: '/avisos/zona',
        title: 'Zonas',
        type: 'link',
        icontype: 'compare_arrows',
        role: 'admin',
    },
    {
        path: '/avisos',
        title: 'Restricciones',
        type: 'sub',
        icontype: 'voice_over_off',
        collapse: 'avisos',
        children: [
            { path: 'edad', title: 'Limite Edad', ab: 'LE' },
            { path: 'aviso', title: 'Aviso', ab: 'AV' },
            { path: 'restriccion', title: 'Restricciones', ab: 'RE' },
            { path: 'promocion', title: 'Promoción', ab: 'PR' },
        ],
        role: 'admin',
    },
    /*{
      path: '/convocatoria/listado',
      title: 'Convocatoria',
      type: 'link',
      icontype: 'calendar_today',
      role: 'convocatoria',
    },*/
    {
      path: '/convocatoria/listado',
      queryParams: {q: '2022'},
      title: 'Convocatoria Escénicas',
      type: 'link',
      icontype: 'calendar_today',
      role: 'convocatoria',
    },
    {
      path: '/convocatoria/listado',
      queryParams: {q: 'musica'},
      title: 'Convocatoria Música',
      type: 'link',
      icontype: 'calendar_today',
      role: 'convocatoria',
    },
    {
      path: '/vinculacion',
      title: 'Vinculacion',
      type: 'sub',
      icontype: 'voice_over_off',
      collapse: 'vinculacion',
      children: [
        { path: 'listado', title: 'Listado', ab: 'LS' },
        { fullpath: '/vinculacion/formulario/listado', title: 'Convocatorias', ab: 'CV' },
        { fullpath: '/avisos/categoriavinculacion', title: 'Categorias', ab: 'CAT' },
        { fullpath: '/avisos/vinculacion_temporada', title: 'Temporadas', ab: 'TEM' },
      ],
      role: 'admin',
    },
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public userName: string = localStorage.getItem('name');

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        const role = localStorage.getItem('role');
        this.menuItems = ROUTES.filter(menuItem => {
            if ( role.indexOf('vinculacion') != -1 && menuItem.title.indexOf('Vinculaciones') == -1 ) {
                return false;
            } else if ( role.indexOf('ligas_venta') != -1 && menuItem.role !== 'ligas_venta' ) {
                return false;
            }

            if (role.indexOf('convocatoria') != -1 && menuItem.role !== 'convocatoria') {
              return false;
            }
            return true;
        });
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
